<template>
  <header class="page-header container" :class="{}">
    <slot></slot>
  </header>
  <!-- / Page Header -->
</template>

<script>
export default {
  props: {
    classes: Array
  }
}
</script>

<style lang="scss">
</style>
