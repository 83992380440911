<template>
  <div class="page-body container" :class="{ 'bg-block':bg  }">
    <slot></slot>
  </div>
  <!-- / Page Body -->
</template>

<script>
export default {
  props: {
    bg: Boolean
  }
}
</script>

<style lang="scss">
</style>
